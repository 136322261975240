import React from "react";
import s from "./styles.module.scss";
import cx from "classnames";

export type ImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {};

const Image: React.FC<ImageProps> = props => {
  return (
    <img {...props} alt={props.alt} className={cx(s.image, props.className)} />
  );
};

export default Image;
