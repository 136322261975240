import React from "react";
import cx from "classnames";
// Base gamut styles & fonts
// We can probably remove the top-level fonts directory and just import this?
import "@codecademy/gamut-styles/core/_fonts.scss";

import { LayoutGrid, Column, Container, Logo } from "@codecademy/gamut";
import SEO from "components/seo";
import { LearnFromHomeContentful, Partner as PartnerType } from "./types";
import Partner from "./sections/Partner";
import Image from "./components/Image";
import Card from "./components/Card";

import s from "./styles.module.scss";
import Footer from "./sections/Footer";
import Share from "./sections/Share";
import EmailSignup from "./sections/EmailSignup";
import Description from "./components/Description";

export type LearnFromHomePageProps = {
  pageContext: {
    contentful: LearnFromHomeContentful;
  };
};

const LearnFromHomePage: React.FC<LearnFromHomePageProps> = ({
  pageContext: { contentful }
}) => {
  const {
    header,
    headerImage,
    clubDescription,
    signUpDescription,
    partnerList,
    footerText,
    metaImage
  } = contentful;
  const codecademyInfo =
    partnerList.find(p => p.name === "Codecademy") || ({} as PartnerType);
  // eslint-disable-next-line global-require,import/no-dynamic-require
  const logoSrc = require(`../../images/ccandfriends.svg`);
  return (
    <Container column center grow={1} fit className={s.lfh}>
      <SEO
        title="Learn From Home Club"
        description={clubDescription.clubDescription}
        image={`https:${metaImage.file.url}`}
      />
      <LayoutGrid rowGap="lg" columnGap="lg" className={s.main}>
        <Column size={{ sm: 12 }}>
          <Image src={logoSrc} alt="Codecademy and friends logo" />
        </Column>
        <Column size={{ sm: 12, md: 6 }} className={s.section}>
          <div>
            <h2>{header}</h2>
            <Description className={s.clubDescription}>
              {clubDescription.clubDescription}
            </Description>
            <EmailSignup description={signUpDescription.signUpDescription} />
          </div>
        </Column>
        <Column
          size={{ sm: 12, md: 6 }}
          className={cx(s.section, s.imageColumn)}
        >
          <Image src={headerImage.file.url} alt={headerImage.title} />
        </Column>

        <Column size={{ sm: 12, md: 8 }} key="codecademy">
          <Partner
            {...codecademyInfo}
            description={codecademyInfo.description?.description}
          />
        </Column>

        <Column size={{ sm: 12, md: 4 }} className={s.sharingColumn}>
          <Card className={s.sharingCard}>
            <LayoutGrid rowGap="sm" columnGap="sm" className={s.sharingGrid}>
              <Column size={{ sm: 6, md: 12 }} className={s.sharingCardColumn}>
                <Share description="Share with your friends and tell them what you will #LearnFromHome!" />
              </Column>
              <Column size={{ sm: 6, md: 12 }} className={s.sharingCardColumn}>
                <Description className={s.footerText}>
                  {footerText.footerText}
                </Description>
              </Column>
            </LayoutGrid>
          </Card>
        </Column>

        {partnerList.map((partner: PartnerType) => {
          if (partner.name === "Codecademy") return null;
          return (
            <Column size={{ sm: 12, md: 6 }} key={partner.name}>
              <Partner
                {...partner}
                description={partner.description.description}
              />
            </Column>
          );
        })}
      </LayoutGrid>
      <Footer />
    </Container>
  );
};

export default LearnFromHomePage;
