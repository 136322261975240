import React from "react";
import { Row, Logo } from "@codecademy/gamut";
import s from "./styles.module.scss";
import FooterReferences from "components/corporate/FooterReferences";

export type FooterProps = {};

const Footer: React.FC<FooterProps> = () => {
  return (
    <div className={s.footer}>
      <Logo className={s.logo} />
      <FooterReferences
        references={[
          { text: `© Codecademy ${new Date().getFullYear()}` },
          {
            text: "Privacy Policy",
            href: "https://codecademy.com/policy",
            className: s.footerText
          },
          {
            text: "Terms of Use",
            href: "https://codecademy.com/terms",
            className: s.footerText
          },
          {
            text: "Help",
            href: "https://help.codecademy.com/hc/en-us",
            className: s.footerText
          }
        ].filter(Boolean)}
      />
    </div>
  );
};

export default Footer;
