import cx from "classnames";
import React from "react";
import styles from "./styles.module.scss";

export type FooterReferencesProps = {
  references: FooterReference[];
};

export type FooterReference = {
  className?: string;
  dataTestid?: string;
  href?: string;
  text: string | null;
};

const FooterReferences: React.FC<FooterReferencesProps> = ({
  references = []
}) => (
  <div className={styles.footerReferences}>
    {references.map(c => {
      if (!c.text) return null;
      if (c.href) {
        return (
          <div className={styles.reference} key={c.href}>
            <a href={c.href} className={c.className} data-testid={c.dataTestid}>
              {c.text}
            </a>
          </div>
        );
      }
      return (
        <div className={cx(styles.reference, c.className)} key={c.text}>
          {c.text}
        </div>
      );
    })}
  </div>
);

export default FooterReferences;
