import React from "react";
import cx from "classnames";
import s from "./styles.module.scss";

export type CardProps = {
  className?: string;
};

const Card: React.FC<CardProps> = ({ className, children }) => {
  return <div className={cx(s.card, className)}>{children}</div>;
};

export default Card;
