import React from "react";
import Card from "templates/LearnFromHome/components/Card";
import cx from "classnames";
import s from "./styles.module.scss";
import { Button, Container } from "@codecademy/gamut";
import Description from "templates/LearnFromHome/components/Description";

export type PartnerProps = {
  name: string;
  url: string;
  logo: {
    file: {
      url: string;
    };
  };
  description: string;
};

const Partner: React.FC<PartnerProps> = ({ name, logo, description, url }) => {
  const isCodecademy = name === "Codecademy";
  return (
    <Card className={isCodecademy ? s.codecademyCard : null}>
      <h3>
        <img src={logo.file.url} alt={name} className={s.logo} />
      </h3>
      <Description
        className={cx(
          s.description,
          isCodecademy ? s.descriptionCodecademy : null
        )}
      >
        {description}
      </Description>
      <Container className={s.buttonContainer}>
        <Button
          theme={isCodecademy ? "white" : "brand-dark-blue"}
          className={s.button}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More
        </Button>
      </Container>
    </Card>
  );
};

export default Partner;
