import React from "react";
import { Markdown } from "@codecademy/gamut";

type DescriptionProps = {
  children: string;
  inline?: boolean;
  className?: string;
};

const Description: React.FC<DescriptionProps> = ({
  children,
  inline,
  className
}) => {
  return <Markdown inline={inline} text={children} className={className} />;
};

export default Description;
