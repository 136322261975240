import React from "react";
import { Row } from "@codecademy/gamut";
import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  InstagramIcon
} from "@codecademy/gamut-icons";
import Description from "templates/LearnFromHome/components/Description";
import s from "./styles.module.scss";
import Uri from "jsuri";
import { SocialMediaShare } from "../types";
import SocialShareIconLink from "./SocialShareIconLink";

const defaultHashtag = "codecademy";

export const shareLinkCreator = (
  formatter: (payload: SocialMediaShare) => Record<string, string>,
  baseUri: string
) => (payload: SocialMediaShare) => {
  const params = formatter(payload);
  const uri = new Uri(baseUri);

  Object.keys(params).forEach(key => {
    uri.addQueryParam(key, params[key]);
  });

  return uri.toString();
};

export type ShareProps = {
  description: string;
};

export const formatShareForFacebook = ({ url, message }: SocialMediaShare) => {
  return {
    ...(url && { href: url + "&utm_source=facebook" }),
    ...(message && { quote: message }),
    hashtag: `#${defaultHashtag}`
  };
};

export const formatShareForTwitter = ({
  url,
  message,
  hashtags,
  mention
}: SocialMediaShare) => {
  return {
    ...(url && { url: url + "&utm_source=twitter" }),
    ...(message && { text: message }),
    ...(hashtags && { hashtags: hashtags.join(",") }),
    ...(mention && { via: mention })
  };
};

export const formatShareForLinkedIn = ({ url }: SocialMediaShare) => {
  return { url: url + "&utm_source=linkedin&utm_term=linkedin" };
};

const Share: React.FC<ShareProps> = ({ description }) => {
  const media = [
    {
      label: "Share on Facebook",
      baseUrl: "https://www.facebook.com/dialog/share?app_id=212500508799908",
      formatShare: formatShareForFacebook,
      icon: FacebookIcon
    },
    {
      label: "Share on Twitter",
      baseUrl: "https://twitter.com/intent/tweet?",
      formatShare: formatShareForTwitter,
      icon: TwitterIcon
    },
    {
      label: "Share on Instagram",
      baseUrl: "https://instagram.com/codecademy",
      formatShare: formatShareForLinkedIn,
      icon: InstagramIcon
    },
    {
      label: "LinkedIn",
      baseUrl: "https://www.linkedin.com/shareArticle",
      formatShare: formatShareForLinkedIn,
      icon: LinkedinIcon
    }
  ];
  return (
    <div className={s.container}>
      <h5>
        <Description inline className={s.description}>
          {description}
        </Description>
        <Row className={s.icons} between="sm">
          {media.map(({ icon, formatShare, baseUrl, label }) => {
            return (
              <SocialShareIconLink
                key={label}
                label={label}
                href={shareLinkCreator(
                  formatShare,
                  baseUrl
                )({
                  url:
                    "https://www.learnfromhome.club?utm_content=social_share&utm_campaign=learn_from_home",
                  message:
                    "I'm joining the Learn From Home Club to learn new skills while I'm staying home. Join me at learnfromhomeclub.com and share what you're going to #learnfromhome!",
                  hashtags: ["codecademy", "learnfromhome"],
                  mention: "codecademy"
                })}
                icon={icon}
              />
            );
          })}
        </Row>
      </h5>
    </div>
  );
};

export default Share;
