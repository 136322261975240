import React, { useState } from "react";
import { Container, Input, Button } from "@codecademy/gamut";
import s from "./styles.module.scss";
import Description from "templates/LearnFromHome/components/Description";
export type EmailSignupProps = {
  description: string;
};

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const EmailSignup: React.FC<EmailSignupProps> = ({ description }) => {
  const [hasSignedUp, setSignUp] = useState(false);
  const [email, setEmail] = useState("");
  const handleSubmit = e => {
    if (!!email) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "email_signup", email })
      })
        .then(() => setSignUp(true))
        .catch(console.error);
    }
    e.preventDefault();
  };
  return (
    <div className={s.container}>
      <h5>
        <Description inline className={s.description}>
          {description}
        </Description>
      </h5>
      <form onSubmit={handleSubmit}>
        <input type="hidden" name="form-name" value="email_signup" />
        <Container align="center" className={s.signupContainer}>
          <Input
            name="email"
            placeholder="Email Address"
            className={s.input}
            onChange={e => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            theme="brand-dark-blue"
            className={s.button}
            disabled={hasSignedUp || !email}
          >
            Sign Up
          </Button>
        </Container>
      </form>
      {hasSignedUp ? (
        <p className={s.successMessage}>Done, you&apos;re subscribed!</p>
      ) : (
        <div className={s.height} />
      )}
    </div>
  );
};

export default EmailSignup;
