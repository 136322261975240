import React from "react";
import styles from "./styles.module.scss";
import { GamutIconProps } from "@codecademy/gamut-icons";

type SocialShareIconLinkProps = {
  icon: React.ComponentType<GamutIconProps>;
  href: string;
  label: string;
};

const SocialShareIconLink: React.FC<SocialShareIconLinkProps> = ({
  icon: IconComponent,
  href,
  label
}) => {
  const onClick = ev => {
    ev.preventDefault();
    const left = (screen.width - 570) / 2;
    const top = (screen.height - 570) / 2;
    const features = `menubar=no,toolbar=no,status=no,width=570,height=570,top=${top},left=${left}`;
    const popup = window.open(href, "_blank", features);
    popup?.focus();
  };

  return (
    <a
      href={href}
      onClick={onClick}
      className={styles.iconContainer}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={label}
    >
      <IconComponent titleId={label} className={styles.icon} size={24} />
    </a>
  );
};

export default SocialShareIconLink;
